.landing {
    background-size: cover;
    background-position: center center;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
}
/* ========================== */
/* rotating icons css */
.circle {
    position: relative;
    top: 50px;
    right: 200px;
    width: 50px;
    height: 50px;
    transform-style: preserve-3d;
    animation: animateCircle 20s linear infinite;
  }
  
  @keyframes animateCircle {
    0% {
      transform: perspective(1000px) rotateY(0deg) rotateX(15deg)
        translateY(-30px);
    }
    100% {
      transform: perspective(1000px) rotateY(360deg) rotateX(15deg)
        translateY(-30px);
    }
  }
  
  .circle .circlespan {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2d2e32;
    box-shadow: 0px 0px 5px #00000080;
    border-radius: 50%;
    transform-origin: center;
    transform-style: preserve-3d;
    transform: rotateY(calc(var(--i) * calc(360deg / 15))) translateZ(300px);
  }
  
  .circle .circlespan .img {
    position: relative;
    top: 8px;
    left: 9px;
    object-fit: cover;
  }

  .img {
    height: 2rem;
  }
    
  /* ===============================/ */

  
