.dark-theme {
    --bg-color: #000;
    --text-color: #fff;
    --btn-color: #fff;
    --ham-bg: #020202;
    --hover-icon: rgb(132 204 22);
    --card-color: #353333;
    --footer-bg: #0a0a0a;
    --card-text-color: #050505;
    --nav-logo: #06b6d4;
  }
  .light-theme {
    --bg-color: #d8d2d2;
    --text-color: #000;
    --btn-color: #5f5f5f;
    --ham-bg: #5f5f5f;
    --hover-icon: rgb(151, 149, 41);
    --card-color: #1b1b1b;
    --footer-bg :#000;
    --card-text-color: #fcfbfb;
    --nav-logo: #000;


}
html {
  scroll-behavior: smooth;
}
/* navbar css */
.nav {
    height: 4.4rem;
    width: 100%;
    background-color: var(--bg-color) ;
    position: fixed;
    z-index: 100;
  }
  
  .nav > .nav-header {
    display: inline;
  }
  
  .nav > .nav-header > .nav-title {
    display: inline-block;
    font-size: 22px;
    color: var(--text-color);
    padding: 10px 10px 10px 10px;
  }
  
  .nav > .nav-btn {
    display: none;
    color: var(--ham-bg);
    
  }
  
  .nav > .nav-links {
    display: inline;
    float: right;
    font-size: 18px;
    font-weight: 400;
    top: 2rem;
  }
  
  .nav > .nav-links > a {
    display: inline-block;
    padding: 13px 10px 13px 10px;
    text-decoration: none;
    color: var(--text-color);
  }
  
  .nav > .nav-links > a:hover {
    background-color: var(--bg-color) ;
  }
  
  .nav > #nav-check {
    display: none;
  }
  
  @media (max-width:800px) {
    .nav > .nav-btn {
      display: inline-block;
      position: absolute;
      right: 2rem;
      top: 1rem;
    }
    .nav > .nav-btn > label {
      display: inline-block;
      width: 50px;
      height: 50px;
      padding: 13px;
    }
    .nav > .nav-btn > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
      background-color: var(--bg-color) ;
    }
    .nav > .nav-btn > label > span {
      display: block;
      width: 25px;
      height: 10px;
      border-top: 2px solid #eee;
    }
    .nav > .nav-links {
      position: absolute;
      display: block;
      width: 100%;
      background-color: var(--bg-color) ;
      height: 0px;
      transition: all 0.3s ease-in;
      overflow-y: hidden;
      top: 4.4rem;
      left: 0px;
      padding: 1rem;
    }
    .nav > .nav-links > a {
      display: block;
      width: 100%;
    }
    .nav > #nav-check:not(:checked) ~ .nav-links {
      height: 0px;
    }
    .nav > #nav-check:checked ~ .nav-links {
      height: calc(50vh - 50px);
      overflow-y: auto;
    }
  }

  /* landing page css */
  .landing {
    background-color: var(--bg-color);
    color: var(--text-color);
  }
  /* about page css */
  .about{
    background-color: var(--bg-color);
    color: var(--text-color);
  }
  /* .SkillIcon:hover {
      color: var(--hover-icon);
  } */

  /* qualification conatiner  */
  .qualification-container {
    background-color: var(--bg-color);
    color: var(--text-color);
    z-index: -10;
  }
  /* .qual-heading {
    color: var(--text-color);
  } */

  /* services css */
  .services {
    background-color: var(--bg-color) ;
  }

  .card {
    width: 100%;
    max-width: 300px;
    min-width: 200px;
    height: 250px;
    background-color: var(--card-color);
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
    border: 2px solid rgba(7, 7, 7, 0.12);
    font-size: 16px;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    z-index: 0;
    font-family: 'Poppins', sans-serif;
  }
  
  .icon {
    margin: 0 auto;
    width: 100%;
    height: 80px;
    max-width: 80px;
    background: linear-gradient(90deg, #7eaaff 0%, #a772a6 40%, rgba(0, 0, 0, 0.28) 60%);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.8s ease;
    background-position: 0px;
    background-size: 200px;
  }
  

  .card .title {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: 30px;
    color: var(--card-text-color);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 4px;
  }
  
  .card .text {
    width: 80%;
    margin: 0 auto;
    font-size: 13px;
    text-align: center;
    margin-top: 20px;
    color: var(--card-text-color);
    font-weight: 200;
    letter-spacing: 2px;
    opacity: 0;
    max-height: 0;
    transition: all 0.3s ease;
  }
  
  .card:hover {
    height: 320px;
  }
  
  .card:hover .text {
    transition: all 0.3s ease;
    opacity: 1;
    max-height: 40px;
  }
  
  .card:hover .icon {
    background-position: -120px;
    transition: all 0.3s ease;
  }
  
  .card:hover .icon {
    color: #ff48fb;
    background-color: black;
    transition: all 0.3s ease;
  }

  /* footer css */
  .footer{
    background-color: var(--footer-bg);
  }
  /* headerclass  css*/
  .headerclass {
    background-color: var(--bg-color);
  }
  .navText {
    color: var(--text-color);
  }
  .navlogo {
    color: var(--nav-logo);
  }
  .dropdown_menu {
    background-color: var(--bg-color);
  }
  .bars {
    background-color: var(--text-color);
  }
  /* ============================== */
  /* slider body  */
  .slidermainBody {
    background-color: var(--bg-color);
  }
  .sliderAnchor{
     color: var(--text-color);
  }
/* ============== */
.projectCodepenLink {
  color: var(--text-color);
}