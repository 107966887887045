.dark-theme {
    --loaderBackground: #000;
    --loader-ball: #fff;
    --loaderSahdow: rgba(138, 129, 129, 0.9);
}

.light-theme {
    --loaderBackground: #fff;
    --loader-ball: #000;
    --loaderSahdow: rgba(0, 0, 0, 0.9)
}

.mainLoader {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--loaderBackground);
}

.loader {
    width: 12.5rem;
    height: 3.75rem;
    position: relative;
    z-index: 1;
}

.loaderCircle {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    background-color: var(--loader-ball);
    left: 15%;
    transform-origin: 50%;
    animation: circle7124 .5s alternate infinite ease;
}

@keyframes circle7124 {
    0% {
        top: 60px;
        height: 5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
    }

    40% {
        height: 20px;
        border-radius: 50%;
        transform: scaleX(1);
    }

    100% {
        top: 0%;
    }
}

.loaderCircle:nth-child(2) {
    left: 45%;
    animation-delay: .2s;
}

.loaderCircle:nth-child(3) {
    left: auto;
    right: 15%;
    animation-delay: .3s;
}

.loaderShadow {
    width: 20px;
    height: 4px;
    border-radius: 50%;
    background-color: var(--loaderSahdow);
    position: absolute;
    top: 62px;
    transform-origin: 50%;
    z-index: -1;
    left: 15%;
    filter: blur(1px);
    animation: shadow046 .5s alternate infinite ease;
}

@keyframes shadow046 {
    0% {
        transform: scaleX(1.5);
    }

    40% {
        transform: scaleX(1);
        opacity: .7;
    }

    100% {
        transform: scaleX(.2);
        opacity: .4;
    }
}

.loaderShadow:nth-child(4) {
    left: 45%;
    animation-delay: .2s
}

.loaderShadow:nth-child(5) {
    left: auto;
    right: 15%;
    animation-delay: .3s;
}