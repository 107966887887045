
 .educationContainer {
    width: 80%;
    margin: 50px auto;
    position: relative;
    overflow: hidden;
 }
 
 .educationContainer:before {
    content: '';
    position: absolute;
    top: 5%;
    left: 50%;
    margin-left: -1px;
    width: 2px;
    height: 100%;
    background: #c515bc;

 }
 
 .timeline-block {
    width: -webkit-calc(50% + 8px);
    width: -moz-calc(50% + 8px);
    width: calc(50% + 8px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    justify-content: space-between;
    clear: both;
 }
 
 .timeline-block-right {
    float: right;
 }
 
 .timeline-block-left {
    float: left;
    direction: rtl
 }
 
 .marker {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #F5F7FA;
    background: #A3e635;
    margin-top: 10px;
    z-index: 1;
 }
 
 .timeline-content {
    width: 95%;
    padding: 0 15px;
 }
 
 .timeline-content h3 {
    margin-top: 5px;
    margin-bottom: 5px;
 }
