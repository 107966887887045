.portlMain {
  background-color: rgba(121, 189, 183, 0.432);
  background: radial-gradient(circle, rgba(194, 197, 213, 1) 0%, rgba(40, 30, 32, 1) 100%);
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
}

.towerPortal:hover {
  transform: translateY(-10px);
  transition-duration: 0.5s;
}

/* ===================================== */
/* portalcss */
:root {
  --deg: 1;
  --x: -50%;
  --y: -50%;
  --speed: 150ms;
}

.portalMain {
  position: relative;
  top: 0;
  z-index: 50;
}

.portalDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(var(--x, -50%), var(--y, -50%)) rotate(0deg);
  font-size: 20vmin;
  width: 3em;
  height: 3em;
  border-radius: 90% 95% 85% 105%;
  background: rgb(255, 8, 0);
  mix-blend-mode: screen;
  filter: hue-rotate(0deg);
  animation: wobble calc(var(--speed) * var(--t)) linear infinite;
  transform-origin: -var(--y) -var(--x);
  box-shadow: 0 0 .5em .2em #000 inset, 0 0 .15em 0 #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portalDiv::after {
  font-size: 1em;
  white-space: nowrap;
}

.portalDiv:nth-child(1) {
  --x: -53%;
  --y: -53%;
  --t: 37;
}

.portalDiv:nth-child(2) {
  --x: -47%;
  --y: -52%;
  --t: 58;
}

.portalDiv:nth-child(3) {
  --x: -45%;
  --y: -50%;
  --t: 46;
}

.portalDiv:nth-child(4) {
  --x: -53%;
  --y: -45%;
  --t: 72;
}

.portalDiv:nth-child(5) {
  --x: -55%;
  --y: -45%;
  --t: 62;
}

@keyframes wobble {
  to {
    filter: hue-rotate(360deg);
    transform: translate(var(--x), var(--y)) rotate(360deg);
  }
}

/* ==============================================
============================================================= */
/* portal version component css */

.verionBody {
  padding: .5rem;
}

.versionCard{
  position: relative;
  height: 210px;
  background: url(../../Assets/v1landing-mob.png);
  background-size: cover;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  transition: 0.2s ease-in-out;
  border: 6px solid black;
}
.versionCard::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px); /* Adjust the blur radius as needed */
    pointer-events: none; /* This ensures the overlay doesn't capture mouse events */
    opacity: 0; /* Initially, the overlay is transparent */
    transition: opacity 0.3s ease; /* Add a smooth transition effect for opacity changes */
}
.versionCard:hover::before {
  opacity: 1; /* Show the overlay on hover */
}

.textBox {
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  transition: 0.2s ease-in-out;
  z-index: 2;
}

.textBox>.text {
  font-weight: bold;
}

.textBox>.head {
  font-size: 50px;
  color: rgb(5, 5, 5);
}

.versionCard:hover>.textBox {
  opacity: 1;
}

.versionCard:hover>.versionCard {
  /* filter: blur(8px); */
  animation: anim 3s infinite;
}

@keyframes anim {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

/* .versionCard:hover ~ .head {
  transform: scale(1.02);
  filter: blur(8px);
} */

.versionComponent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.versionText {
  font-size: large;
  font-weight: 700;
}