.dark-theme {
  --toggleButton-boxShadow: #aaa0a0 ;
}
.light-theme {
  --toggleButton-boxShadow: #323232;
}
.themeswitch {
  --input-focus: #30d651;
  --font-color: #323232;
  --font-color-sub: #666;
  --bg-color: #fff;
  --bg-color-alt: #666;
  --main-color: #323232;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 2rem;
  height: 1rem;
}

.themetoggle {
  opacity: 0;
  width: 0;
  height: 0;
}

.themeslider {
  box-sizing: border-box;
  border-radius: 5px;
  border: 2px solid var(--main-color);
  box-shadow: 4px 4px var(--toggleButton-boxShadow);
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bg-colorcolor);
  transition: 0.3s;
}

.themeslider:before {
  box-sizing: border-box;
  position: absolute;
  content: "";
  height: 1rem;
  width: 1rem;
  border: 2px solid var(--main-color);
  border-radius: 5px;
  left: -2px;
  bottom: 2px;
  background-color: var(--bg-color);
  box-shadow: 0 3px 0 var(--main-color);
  transition: 0.3s;
}

.themetoggle:checked + .themeslider {
  background-color: var(--input-focus);
}

.themetoggle:checked + .themeslider:before {
  transform: translateX(1rem);
}