.HTML5:hover {
    color: #DD4B25 ;
    /* E96128 */
}

.CSS3:hover {
    color: #018BCF ;
}

.JavaScript:hover {
    color: #E9D44D ;
}

.react:hover {
    color: #4FD1F4;
}

.BootStrap:hover {
    color: #7D68F0 ;
}

.Tailwind:hover {
    color: #36B7F1 ;
}

.Git:hover {
    color: #E94E31 ;
}

.github:hover {
    color: #9e9898 ;
}

.php:hover {
    color: #294555 ;
}

.node:hover {
    color: #519941 ;
}

.WordPress:hover {
    color: #117298 ;
}

.DataBases:hover {
    color: #4086C1 ;
}

.Python:hover {
    color: #2F6696 ;
}

.Adobe:hover {
    color: #E64025 ;
}

.threeD:hover {
    color:rgb(198,60,29) ;
}
