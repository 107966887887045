.slidercard {
  height: 13.75rem;
  width: 22rem;
  /* background: rgb(103, 225, 255); */
  transition: all 0.4s;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 5px  rgba(0, 0, 0, 0.705);
  font-size: 10px;
  font-weight: 900;
  overflow: hidden;
}

.slidercard:hover {
  border-radius: 15px;
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: 0px 0px 10px 5px  rgba(0, 0, 0, 0.705);
  background: rgb(62, 63, 67);
}

.sliderfirst-content {
  height: 100%;
  width: 100%;
  transition: all 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  border-radius: 15px;
}

.slidercard:hover .sliderfirst-content {
  height: 0px;
  opacity: 0;
}

.slidersecond-content {
  height: 0%;
  /* width: 100%; */
  opacity: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  transition: all 0.4s;
  font-size: 0px;
  transform: rotate(90deg) scale(-1);
}

.slidercard:hover .slidersecond-content {
  opacity: 1;
  height: 100%;
  font-size: 1.3rem;
  transform: rotate(0deg);
}
