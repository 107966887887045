*{
  padding:0;
  margin:0;
}


li{
  list-style:none;
}

a{
  text-decoration:none;
  color:white;
}
.navlogo:hover {
  color: #84cc16;
}
a:hover{
  color:orange;
}

header{
  position:relative;
  padding:0 2rem;
  background-color:black;
}
.headerclass {
  z-index: 9;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
}
.navbar{
  display:flex;
  width:100%;
  height:60px;
  max-width:1200px;
  margin:0 auto;
  align-items:center;
  justify-content:space-between;
  position: sticky; 
  top: 50px;
}

.navbar .logo a{
  font-size:1.5rem;
  font-weight:bold;
}

.navbar .links{
  display:flex;
  gap:2rem;
}

.navbar .toggle_btn{
  color:#fff;
  cursor:pointer;
  display:none;
  font-size:1.5rem;
}

.action_btn{
    background-image: linear-gradient(to right, #06b6d4 , #3b82f6);
    padding:0.5rem 1rem;
    border:none;
    outline:none;
    border-radius:20px;
    font-size:1rem;
    font-weight:bold;
}

.action_btn:hover{
  color:rgb(10, 10, 10);
  scale:0.98;
}
.action_btn:active{
  scale:0.95;
}

.dropdown_menu{
  display:none;
  right:2rem;
  position:absolute;
  backdrop-filter:blur(15px);
  top:60px;
  height:0px;
  width: calc(100% - 10%);
  border-radius:10px;
  overflow:hidden;
  transition: 1s ease-in-out cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.dropdown_menu li{
  padding:0.7rem;
  display:flex;
  justify-content:center;
  align-items:center;
}
.dropdown_menu.open{
  height:fit-content;
}

.dropdown_menu .action_btn{
  width:100%;
  display:flex;
  justify-content:center;
}

@media(max-width:640px){
  .navbar .links,
  .navbar .action_btn{
    display:none;
  }
  .navbar .toggle_btn{
    display:block;
  }
  .dropdown_menu{
    display:block;
  }
}

@media(max-width:576px){
  .dropdown_menu{
    left:2rem;
    width:unset;
  }
}

/* list icon css */
#checkbox {
  display: none;
}

.toggle {
  position: relative;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  transition-duration: .5s;
}

.bars {
  width: 100%;
  height: 4px;
  border-radius: 4px;
}

#bar2 {
  transition-duration: .8s;
}

#bar1,#bar3 {
  width: 70%;
}

#checkbox:checked + .toggle .bars {
  position: absolute;
  transition-duration: .5s;
}

#checkbox:checked + .toggle #bar2 {
  transform: scaleX(0);
  transition-duration: .5s;
}

#checkbox:checked + .toggle #bar1 {
  width: 100%;
  transform: rotate(45deg);
  transition-duration: .5s;
}

#checkbox:checked + .toggle #bar3 {
  width: 100%;
  transform: rotate(-45deg);
  transition-duration: .5s;
}

#checkbox:checked + .toggle {
  transition-duration: .5s;
  transform: rotate(180deg);
}

/* ====================================
logo img csss */

.logoimg {
  width: 4rem;
  padding: .2rem;
  margin-top: .3rem;
}