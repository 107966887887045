.mainContainer {
    background: linear-gradient(to bottom left, #8defe7 40%, #ffe89e 100%);
}
.h1 {
    font-size: 0.9em;
    font-weight: 100;
    letter-spacing: 3px;
    padding-top: 5px;
    color: #fcfcfc;
    padding-bottom: 5px;
    text-transform: uppercase;
}

.alert {
    font-weight: 700;
    letter-spacing: 5px;
    color: red;
}
.p {
    margin-top: -5px;
    font-size: 1em;
    font-weight: 500;
    color: #5e5e5e;
    letter-spacing: 1px;
}
button {
    cursor: pointer;
}
#error-box {

    width: 25%;
    height: 50%;
    /* background: linear-gradient(to bottom left, #ef8d9c 40%, #ffc39e 100%); */
    border-radius: 20px; 
}

.face2 {
    position: absolute;
    width: 22%;
    height: 22%;
    background: #fcfcfc;
    border-radius: 50%;
    border: 1px solid #777;
    top: 21%;
    left: 37.5%;
    z-index: 2;
    background-color: #918585;
    animation: roll 3s ease-in-out infinite;
}
.eye {
    position: absolute;
    width: 5px;
    height: 5px;
    background: black;
    border-radius: 50%;
    top: 40%;
    left: 20%;
}
.right {
    left: 68%;
}
.mouth {
    position: absolute;
    top: 43%;
    left: 41%;
    width: 7px;
    height: 7px;
    border-radius: 50%;
}
.sad {
    top: 49%;
    border: 2px solid;
    border-color: #141414 transparent transparent #0e0d0d;
    transform: rotate(45deg);
}
.shadow {
    position: absolute;
    width: 21%;
    height: 3%;
    opacity: 0.5;
    background: #777;
    left: 40%;
    top: 43%;
    border-radius: 50%;
    z-index: 1;
}
.scale {
    animation: scale 1s ease-in infinite;
}
.move {
    animation: move 3s ease-in-out infinite;
}
.message {
    position: absolute;
    width: 100%;
    text-align: center;
    height: 40%;
    top: 47%;
}
.button-box {
    position: absolute;
    background: #fcfcfc;
    width: 50%;
    height: 15%;
    border-radius: 20px;
    top: 73%;
    left: 25%;
    outline: 0;
    border: none;
    box-shadow: 2px 2px 10px rgba(119, 119, 119, .5);
    transition: all 0.5s ease-in-out;
}
.button-box:hover {
    background: #efefef;
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
}
@keyframes bounce {
    50% {
        transform: translateY(-10px);
   }
}
@keyframes scale {
    50% {
        transform: scale(0.9);
   }
}
@keyframes roll {
    0% {
        transform: rotate(0deg);
        left: 25%;
   }
    50% {
        left: 60%;
        transform: rotate(168deg);
   }
    100% {
        transform: rotate(0deg);
        left: 25%;
   }
}
@keyframes move {
    0% {
        left: 25%;
   }
    50% {
        left: 60%;
   }
    100% {
        left: 25%;
   }
}
