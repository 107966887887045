input[type="text"], input[type="text"], input[type="email"], input[type="tel"] {
    border: 1px solid #dbdbdb;
    border-radius: 0.5rem;
    color: #333;
    height: 42px;
    padding: 0 0 0 20px;
    width: 100%;
    outline: 0;
  }
  .contacttextarea {
    border: 1px solid #dbdbdb;
    border-radius: 0.5rem;
    padding: 1rem;
    outline: 0;
  }
  input[type="submit"] {
    display: inline-block;
    font-size: 16px;
    text-transform: uppercase;
    background: transparent;
    border: 2px solid;
    font-weight: 500;
    padding: 10px 20px;
    outline: 0;
    cursor: pointer;
    color: #103e65;
    transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -webkit-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -moz-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -ms-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -o-transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  input[type="submit"]:hover {
    background-color: #f85508;
    border-color: #f85508;
    color: #fff;
  }
  .anchorlink {
    text-decoration: none;
  }
  .contactRight {
    /* background: url("../../Assets/map.jpeg"); */
    /* opacity: 0.5; */
  }